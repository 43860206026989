import axios from "axios"
axios.defaults.headers.post["Content-Type"] = "JSON (application/json)"
axios.defaults.headers.put["Content-Type"] = "JSON (application/json)"
const baseURL = `${process.env.API_ROOT}/api/reviews`

export const create = data => axios.post(baseURL, data)
export const update = data => axios.put(baseURL, data)
// export const inform = ({ message }) =>
//   axios.post(baseURL + `/clientError`, {
//     message,
//   })
// export const reportMissingParams = missingParams =>
//   inform({
//     message: `Reviews link was reached and initial form was filled out, but url was missing the following required parameters: ${missingParams
//       .map(p => `\n👉  \`${p}\``)
//       .join("")}`,
//   })
